export const GET_JOBORDERS = 'GET_JOBORDERS';
export const GET_PR = 'GET_PR';
export const ADD_PR = 'ADD_PR';
export const EDIT_PR = 'EDIT_PR';
export const DELETE_PR = 'DELETE_PR';
export const GET_MASTERLIST = 'GET_MASTERLIST';


export const reducer = (state, action) => {

  const { type, payload } = action;
  switch (type) {
    case GET_JOBORDERS:
      return {
        ...state,
        joList: payload.page > 1
          ? state.joList.concat(payload.joList)
          : payload.joList,
          joListLength: payload.joListLength,
      }
    case GET_MASTERLIST:
      return {
        ...state,
        masterlistOpt: payload.masterlistOpt
      }
    case GET_PR:
      return {
        ...state,
        prList: payload.prList,
        prListLength: payload.prListLength,
      }
    case ADD_PR:
      return {
        ...state,
        joList: [
          ...state.joList.slice(0, state.joList.findIndex(jo => jo.jo_id === payload.id)),
          {
            ...state.joList[state.joList.findIndex(jo => jo.jo_id === payload.id)],
            prCount: state.joList[state.joList.findIndex(jo => jo.jo_id === payload.id)].prCount + 1,
            hasPr: true,
          },
          ...state.joList.slice(state.joList.findIndex(jo => jo.jo_id === payload.id) + 1)
        ]
      }
    case EDIT_PR:
      return {
        ...state,
        prList: [
          ...state.prList.slice(0, state.prList.findIndex(pr => pr.id === payload.newItem.id)),
          payload.newItem,
          ...state.prList.slice(state.prList.findIndex(pr => pr.id === payload.newItem.id) + 1)
        ]
      }
    case DELETE_PR:
      return {
        ...state,
        prList : state.prList.filter(pr => pr.id !== payload.id),
        prListLength : state.prListLength - 1
      }
    default:
      return state
  }
}