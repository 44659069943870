import React, { createContext, useState } from 'react';
import { displayErrors } from '../config/display';
import axios from 'axios';
export const Utils = createContext({});

const UtilsContext = ({ children }) => {
	const [loading, setLoading] = useState(false);
	const [units, setUnits] = useState([]);
	const API = process.env.REACT_APP_API_URL || 'http://api.exelpack.site/api/';

	const getOptionUnits = async () => {
		try {
			const res = await axios.get(API + 'options/units');
			setUnits(res.data.unitsOption);
		} catch (err) {
			displayErrors(err);
		}
	};

	return (
		<Utils.Provider
			value={{ loading, setLoading, getOptionUnits, unitsOpt: units }}
		>
			{children}
		</Utils.Provider>
	);
};

export default UtilsContext;
